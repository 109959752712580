import React from 'react';
import './App.css';
import { Speech } from './interfaces/speech';
import { BotScript } from './interfaces/bot-script';
import { botScripts } from './datas/bot-scripts';

function App() {
  // set up initial conversation
  const [activeBotScriptId, setActiveBotScriptId] = React.useState(1);
  const initialBotScript: BotScript = botScripts[0];
  const initialSpeech: Speech = { order: 0, text: initialBotScript.text[0], owner: 'bot' };
  const [conversation, setConversation] = React.useState([initialSpeech]);
  const initialAnswers = initialBotScript.expectedAnswers;
  const [answers, setAnswers] = React.useState(initialAnswers);

  // 回答が選択される
  const answerSelected = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const answer = answers[index];
    if (answer) {
      // ユーザーの回答を登録
      const newSpeech = { order: conversation.length, text: answer.text, owner: 'user' };
      // 会話にユーザーの回答を追加
      const updatedConversation = [...conversation, newSpeech];
      // 次のbotの発言を更新
      setActiveBotScriptId(answer.nextBotScriptId);
      // 会話を更新
      setConversation(updatedConversation);
    }
  }

  // botの発言が更新される
  React.useEffect(() => {
    if (activeBotScriptId === 1) {
      return;
    }
    // botの発言を取得
    const newBotScript = botScripts.find(botScript => botScript.id === activeBotScriptId);
    if (newBotScript) {
      // botの発言を登録
      const newSpeech = { order: conversation.length, text: newBotScript.text[0], owner: 'bot' };
      // 会話にbotの発言を追加
      const updatedConversation = [...conversation, newSpeech];
      // 会話を更新
      setConversation(updatedConversation);
      if (newBotScript.isEnd) {
        // 1秒後に指定されたURLに遷移
        setTimeout(() => {
          const link = new HTMLAnchorElement();
          link.href = newBotScript.url ? newBotScript.url : '';
          link.click();
        }, 1000);
      } else {
        // 期待する回答を更新
        setAnswers(newBotScript.expectedAnswers);
      }
    }
  }, [activeBotScriptId])

  return (
    <div className="App">
      <div id='chatting-area'>
        {
          conversation.map((speech, index) => {
            if (speech.owner === 'user') {
              return (
                <div className='user-speech' key={index}>
                  {speech.text}
                </div>
              )
            } else {
              return (
                <div className='bot-speech' key={index}>
                  {speech.text}
                </div>
              )
            }
          })
        }
      </div>
      <div id='input-area'>
        {
          answers.map((answer, index) => {
            return (
              <button className='answer-button' key={index} onClick={(e) => answerSelected(e, index)}>
                {answer.text}
              </button>
            )
          })
        }
      </div>
    </div>
  );
}

export default App;
