import { BotScript } from "../interfaces/bot-script";

export const botScripts: BotScript[] = [
  {
    id: 1,
    text: ['こんにちは！脱毛に興味があるんだね！'],
    expectedAnswers: [
      {
        text: 'はい',
        nextBotScriptId: 2
      }
    ],
    isEnd: false
  },
  {
    id: 2,
    text: ['美容脱毛と医療脱毛、どちらがいいかな？'],
    expectedAnswers: [
      {
        text: '美容脱毛',
        nextBotScriptId: 3
      },
      {
        text: '医療脱毛',
        nextBotScriptId: 4
      }
    ],
    isEnd: false
  },
  {
    id: 3,
    text: ['どんな美容脱毛がしたいのかな？'],
    expectedAnswers: [
      {
        text: '痛くない',
        nextBotScriptId: 5
      },
      {
        text: '安い',
        nextBotScriptId: 6
      },
      {
        text: '効果が出るのが早い',
        nextBotScriptId: 7
      }
    ],
    isEnd: false
  },
  {
    id: 4,
    text: ['どんな医療脱毛がしたいのかな？'],
    expectedAnswers: [
      {
        text: '痛くない',
        nextBotScriptId: 8
      },
      {
        text: '安い',
        nextBotScriptId: 9
      },
      {
        text: '効果が出るのが早い',
        nextBotScriptId: 10
      }
    ],
    isEnd: false
  },
  {
    id: 5,
    text: ['痛くない脱毛なら、エステサロンがおすすめだよ！'],
    expectedAnswers: [],
    isEnd: true,
    url: 'https://salesbusiness-shop.com/g/archives/443/'
  }
]